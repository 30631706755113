import _ from 'lodash';

const scheduleDates = [
    [Date.parse("1/1/2024"),Date.parse("9/9/2024")],
    [Date.parse("9/10/2024"),Date.parse("9/16/2024")],
    [Date.parse("9/17/2024"),Date.parse("9/23/2024")],
    [Date.parse("9/24/2024"),Date.parse("9/30/2024")],
    [Date.parse("10/1/2024"),Date.parse("10/7/2024")],
    [Date.parse("10/8/2024"),Date.parse("10/14/2024")],
    [Date.parse("10/15/2024"),Date.parse("10/21/2024")],
    [Date.parse("10/22/2024"),Date.parse("10/28/2024")],
    [Date.parse("10/29/2024"),Date.parse("11/4/2024")],
    [Date.parse("11/5/2024"),Date.parse("11/11/2024")],
    [Date.parse("11/12/2024"),Date.parse("11/18/2024")],
    [Date.parse("11/19/2024"),Date.parse("11/25/2024")],
    [Date.parse("11/26/2024"),Date.parse("12/2/2024")],
    [Date.parse("12/3/2024"),Date.parse("12/9/2024")],
    [Date.parse("12/10/2024"),Date.parse("12/16/2024")],
    [Date.parse("12/17/2024"),Date.parse("12/23/2024")],
    [Date.parse("12/24/2024"),Date.parse("12/30/2024")],
    [Date.parse("12/31/2024"),Date.parse("1/6/2025")]
]

export default () => {

    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const todayRef = Date.parse(today.toLocaleDateString())
    // const todayRef = Date.parse('9/13/2024')
    let week = 1
    _.forEach(scheduleDates, weekEndpoints => {
        if ( todayRef >= weekEndpoints[0] && todayRef <= weekEndpoints[1]){
            return false
        } else {
            week += 1
        }
            
    })

    return week
}