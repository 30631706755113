import React, { Component } from "react";
import M from "materialize-css";
import { connect } from "react-redux";
import { autoCompleteChangedA } from "../actions";
import { fetchPlayers, setSearchPlayerA, fetchStatsA } from '../actions';

class AutocompleteA extends Component  {
  async componentDidMount() {
    this.props.autoCompleteChangedA("");
    await this.props.fetchPlayers();
    const pids = this.props.players[1]
    const searchpid=this.props.setSearchPlayerA;
    const getstats=this.props.fetchStatsA;
    const searchChanged = this.props.autoCompleteChangedA;
    const options = {
      //Data object for autocomplete
      
      data: this.props.players[0],
    //   data: {
    //     Apple: null,
    //     Acer: null,
    //     Alcatel: null,
    //     Asus: null,
    //     Huawei: null,
    //     HTC: null,
    //     Karbonn: null,
    //     Lenovo: null,
    //     Lava: null,
    //     LG: null,
    //     Microsoft: null,
    //     Meizu: null,
    //     Nokia: null,
    //     Oppo: null,
    //     OnePlus: null,
    //     Sony: null,
    //     Vivo: null,
    //     Yu: null,
    //     Google: "https://placehold.it/250x250"
    //   },

      //Limit of results autocomplete shows
      limit: 5,

      //Callback function for Autocomplete
      onAutocomplete() {
        
        let input = document.getElementById("autocomplete-inputA");
        searchpid(pids[input.value])
        getstats(pids[input.value])
        searchChanged(input.value)
      },

      //Minimum number of characters before autocomplete starts.
      minLength: 1
    };
    M.Autocomplete.init(this.AutocompleteA, options);

    //Instance Plugin
    //let instance = M.Autocomplete.getInstance(this.Autocomplete);

    // instance.open();
    // instance.updateData({
    //   "Nothing": null
    // });
    // instance.destroy();
  }

  onAutoCompleteChange = event => {
    this.props.autoCompleteChangedA(event.target.value);
  };



  render() {
    //console.log(this.props.autoCompleteValue);
    return (
      <div>
        <div className="">
          <div className="">
            <div className="">
              <div className="input-field">
                <input onFocus={(e) => e.target.value = ""}
                  placeholder="Search Players..."
                  style={{fontFamily: "Gruppo"}}
                  autoComplete="off"
                  ref={AutocompleteA => {
                    this.AutocompleteA = AutocompleteA;
                  }}
                  type="text"
                  id="autocomplete-inputA"
                  className="autocomplete"
                  onChange={this.onAutoCompleteChange}
                  value={this.props.autoCompleteValueA}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  autoCompleteValueA: state.redA.autoCompleteValueA,
  players: state.players,
  searchPlayerA: state.searchPlayerA
});

export default connect(
  mapStateToProps,
  { autoCompleteChangedA, fetchPlayers, setSearchPlayerA, fetchStatsA }
)(AutocompleteA);
