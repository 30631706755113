const getInsideOutside = {
    ARI:"both",
    ATL:"both",
    BAL:"outside",
    BUF:"outside",
    CAR:"outside",
    CHI:"outside",
    CIN:"outside",
    CLE:"outside",
    DAL:"both",
    DEN:"DEN",
    DET:"inside",
    GB:"outside",
    HOU:"both",
    IND:"both",
    JAX:"outside",
    KC:"outside",
    LV:"inside",
    LAR:"inside",
    LAC:"inside",
    MIA:"outside",
    MIN:"inside",
    NE:"outside",
    NO:"inside",
    NYG:"outside",
    NYJ:"outside",
    PHI:"outside",
    PIT:"outside",
    SF:"outside",
    SEA:"outside",
    TB:"outside",
    TEN:"outside",
    WAS:"outside",
    WSH:"outside"
    }

    export default getInsideOutside