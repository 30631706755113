import {FETCH_USER, REDUCE_CREDITS, REDUCE_CREDITS_COMP} from '../actions/types';

export default function(state = null,action) {
    switch (action.type) {
        case FETCH_USER:
            return action.payload || false;
        case REDUCE_CREDITS:
            return action.payload || false;
        case REDUCE_CREDITS_COMP:
            return action.payload || false;
        default:
            return state;
    }
}