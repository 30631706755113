import React, {Component} from 'react';
import { fetchPlayers, fetchName , setSearchPlayer} from '../actions';
import {connect} from 'react-redux';
import _ from 'lodash';
import Chart from "chart.js/auto";
import zoomPlugin from 'chartjs-plugin-zoom';
import { Line } from "react-chartjs-2";
import { withRouter } from "react-router-dom";
import scheduleOpponents from '../utils/schedule';
import getCurrentWeek from '../utils/getCurrentWeek';
import teamNameMap from '../utils/teamNameMap';
import toolTipMap from '../utils/toolTipMap';
import getInsideOutside from '../utils/getInsideOutside';
import lastYearAverages from '../utils/lastYearAverages';
import { Radar } from 'react-chartjs-2';
import dbTeamMap from '../utils/dbTeamMap';
import {Link} from 'react-router-dom';

class Report extends Component {

    state = {
        data: [],
        isQB: false,
        reportName: ""
    }
    // constructor(props) {
    //     super(props)

    //     this.state = {
    //         data: [],
    //         isQB: false
    //     }
    // }

    async componentDidMount() {
        Chart.register(zoomPlugin)
        const mobileWidth = 600
        const lastYear = 2022
        const thisYear = 2023
        if (this.props.stats.length > 0){
            let currentWeek = getCurrentWeek()
            let x = await this.props.fetchName(this.props.searchPlayer)
            x = {...x,
                team : dbTeamMap[x.team]}
            let currentOpponent = scheduleOpponents[x.team.toUpperCase()][currentWeek-1]
            let currentLocation = ""
            let titleStr = ""
            if (currentOpponent.charAt(0) === "@"){
                currentLocation = "AWAY"
                currentOpponent = currentOpponent.slice(1)
                titleStr = 'on the Road'
            } else {
                currentLocation = "HOME"
                titleStr = 'at Home'
            }
            let insideOutside = getInsideOutside[toolTipMap[currentOpponent]]
            let titleStr2 = ""
            if (insideOutside === "inside") {
                titleStr2 = "Indoors"
            } else {
                titleStr2 = "Outdoors"
            }
                
            // this.props.setSearchPlayer("")
            let averages = []
            let averagesLabels = []
            this.setState({reportName : x.name})
            let stats = ["pass_td","pass_yds","pass_cmp","pass_int","pass_rating","rush_att","rush_yds","rush_td","targets","rec","rec_yds","rec_td","fga","fgm","xpa","xpm","def_int","sacks","tackles_combined","fumbles_forced",
                         "pass_yds_byweek","pass_td_byweek","pass_cmp_byweek","rush_att_byweek","rush_yds_byweek","rush_td_byweek","rec_byweek","rec_yds_byweek","rec_td_byweek",
                         "pass_yds_byopp","pass_td_byopp","pass_cmp_byopp","rush_att_byopp","rush_yds_byopp","rush_td_byopp","rec_byopp","rec_yds_byopp","rec_td_byopp",
                         "pass_yds_byhoa","pass_td_byhoa","pass_cmp_byhoa","rush_att_byhoa","rush_yds_byhoa","rush_td_byhoa","rec_byhoa","rec_yds_byhoa","rec_td_byhoa",
                         "pass_yds_byino","pass_td_byino","pass_cmp_byino","rush_att_byino","rush_yds_byino","rush_td_byino","rec_byino","rec_yds_byino","rec_td_byino",
                        ]
            let qbChartTitle=['Passing TDs', 'Passing Yards','Completions','Passing Interceptions','QBR',"Rushing Attempts","Rushing Yards","Rushing TDs","Receiving Targets","Receiving Catches","Receiving Yards","Receiving TDs","Field Goals Attempted","Field Goals Made","Extra Points Attempted","Extra Points Made","Interceptions","Sacks","Tackles","Forced Fumbles",
                              `Passing Yards on Week ${currentWeek}`,`Passing Touchdowns on Week ${currentWeek}`,`Passing Completions on Week ${currentWeek}`,`Rushing Attempts on Week ${currentWeek}`,`Rushing Yards on Week ${currentWeek}`,`Rushing Touchdowns on Week ${currentWeek}`,`Receptions on Week ${currentWeek}`,`Receiving Yards on Week ${currentWeek}`,`Receiving Touchdowns on Week ${currentWeek}`,
                              `Passing Yards Against the ${teamNameMap[currentOpponent]}`,`Passing Touchdowns Against the ${teamNameMap[currentOpponent]}`,`Passing Completions Against the ${teamNameMap[currentOpponent]}`,`Rushing Attempts Against the ${teamNameMap[currentOpponent]}`,`Rushing Yards Against the ${teamNameMap[currentOpponent]}`,`Rushing Touchdowns Against the ${teamNameMap[currentOpponent]}`,`Receptions Against the ${teamNameMap[currentOpponent]}`,`Receiving Yards Against the ${teamNameMap[currentOpponent]}`,`Receiving Touchdowns Against the ${teamNameMap[currentOpponent]}`,
                              `Passing Yards ${titleStr}`,`Passing Touchdowns ${titleStr}`,`Passing Completions ${titleStr}`,`Rushing Attempts ${titleStr}`,`Rushing Yards ${titleStr}`,`Rushing Touchdowns ${titleStr}`,`Receptions ${titleStr}`,`Receiving Yards ${titleStr}`,`Receiving Touchdowns ${titleStr}`,
                              `Passing Yards ${titleStr2}`,`Passing Touchdowns ${titleStr2}`,`Passing Completions ${titleStr2}`,`Rushing Attempts ${titleStr2}`,`Rushing Yards ${titleStr2}`,`Rushing Touchdowns ${titleStr2}`,`Receptions ${titleStr2}`,`Receiving Yards ${titleStr2}`,`Receiving Touchdowns ${titleStr2}`
                             ]
            let mobileTitles = ['Pass TD','Pass Yds','Cmp','Int','QBR','Rushes','Rush Yds','Rush TDs','Rec','Tar','Catches','Rec Yds','Rec TDs']
            let skipLoop = false
         
            _.range(1,stats.length+1).forEach((current,index) => {
                let tmp = stats[index].split('_')
                // console.log(tmp)
                
                // console.log(chartType)
                let strCheck = ''
                if (tmp.length > 2) {
                    strCheck = tmp.slice(0,-1).join('_')
                } else {
                    if (tmp[tmp.length -1] === 'byweek' || tmp[tmp.length -1] === 'byopp' || tmp[tmp.length -1] === 'byhoa' || tmp[tmp.length -1] === 'byino'){
                        strCheck = tmp[0]
                    } else {
                        strCheck = stats[index]
                    }
                }
                if (this.props.stats[0].hasOwnProperty(strCheck)){
                    let statsArr = []
                    let labels = []
                    let matchups = []
                    let lasyYearArr = []
                    skipLoop = false
                    _.forEach(this.props.stats, game => {
                        if (tmp[tmp.length-1] === "byweek") {
                            if (game["week"] === currentWeek) {
                                statsArr.push(game[tmp.slice(0,-1).join('_')])
                                labels.push(game["year"]+" - Week "+game["week"])
                                if (game["hoa"]==='AWAY'){
                                    matchups.push("@"+game["opponent"])
                                } else {
                                    matchups.push(game["opponent"])
                                }
                                // chartType = 'byweek'
                            }
                        } else if (tmp[tmp.length-1] === "byopp"){
                            currentOpponent = scheduleOpponents[x.team.toUpperCase()][currentWeek-1]
                            if (currentOpponent.charAt(0) === "@"){
                                currentOpponent = currentOpponent.slice(1)
                            } 
                            currentOpponent = toolTipMap[currentOpponent]
                            if (game["opponent"] === currentOpponent) {
                                statsArr.push(game[tmp.slice(0,-1).join('_')])
                                labels.push(game["year"]+" - Week "+game["week"])
                                if (game["hoa"]==='AWAY'){
                                    matchups.push("@"+game["opponent"])
                                } else {
                                    matchups.push(game["opponent"])
                                }
                                // chartType = 'byopp'
                            } 
                        } else if (tmp[tmp.length-1] === "byhoa"){
                            if (game["hoa"] === currentLocation) {
                                statsArr.push(game[tmp.slice(0,-1).join('_')])
                                labels.push(game["year"]+" - Week "+game["week"])
                                if (game["hoa"]==='AWAY'){
                                    matchups.push("@"+game["opponent"])
                                } else {
                                    matchups.push(game["opponent"])
                                }
                                // chartType = "byhoa"
                            } 
                        } else if (tmp[tmp.length-1] === "byino"){
                            let ino = getInsideOutside[game["opponent"]]
                            if (ino === 'inside'){
                                ino = 'INDOORS'
                            } else {
                                ino = 'OUTDOORS'
                            }
                            if (ino === game['location']) {
                                statsArr.push(game[tmp.slice(0,-1).join('_')])
                                labels.push(game["year"]+" - Week "+game["week"])
                                if (game["hoa"]==='AWAY'){
                                    matchups.push("@"+game["opponent"])
                                } else {
                                    matchups.push(game["opponent"])
                                }
                                // chartType = 'byino'
                            } 
                        } else {
                            statsArr.push(game[stats[index]])
                            if (game["year"]===lastYear || game["year"]===thisYear){
                                lasyYearArr.push(game[stats[index]])
                            }
                            // chartType = 'base'
                            labels.push(game["year"]+" - Week "+game["week"])
                        if (game["hoa"]==='AWAY'){
                            matchups.push("@"+game["opponent"])
                        } else {
                            matchups.push(game["opponent"])
                        }
                        }
                        
                        
                    })
                    if (statsArr.length === 0) {
                        skipLoop = true
                    }
                    let tmp1=0
                    _.range(0,statsArr.length).forEach((current,index) => {
                        if (statsArr[index]==='0' || statsArr[index]==='0.0' || statsArr[index]==='') {
                            tmp1 += 1
                        }
                    })
                    if (tmp1 === statsArr.length) {
                        skipLoop = true
                    }
                    // let playerStats=[passYdsArr,passTdsArr,passCompArr,passIntsArr,passQbrArr]
                    this.setState({isQB : true})
                    if (skipLoop === false){
                    const average = array => array.reduce((a, b) => a + b) / array.length
                    let avArr = Array(statsArr.length).fill(average(statsArr.map(Number))).map(function(each_element){
                        return Number(each_element.toFixed(2));
                    })
                    let avArrLastYear = []
                    if (lasyYearArr.length > 0){
                        avArrLastYear = Array(lasyYearArr.length).fill(average(lasyYearArr.map(Number))).map(function(each_element){
                            return Number(each_element.toFixed(2));
                        })
                    }
                    if (index <= 11){
                        averages.push(avArrLastYear[0]/lastYearAverages[qbChartTitle[index]])
                        if (window.innerWidth <= mobileWidth) {
                            averagesLabels.push(mobileTitles[index])
                        } else {
                            averagesLabels.push(qbChartTitle[index])
                        }
                    }
                    let PointRadius = 0
                    if (statsArr.length===1){
                        PointRadius = 2
                    }
         
                    // _.range(1, 6).forEach((current,index)=>{
                        this.setState(state=>({data : [...state.data,{
                            matchup: matchups,
                            labels: labels,
                            averages: averages,
                            averagesLabels: averagesLabels,
                            datasets: [
                                {
                                    title: qbChartTitle[index],
                                    backgroundColor: "rgb(30, 144, 255)",
                                    borderColor: "rgb(30, 144, 255)",
                                    data: statsArr,
                                    radius: PointRadius,
                                    hitRadius: 5
                                },
                                {
                                    // title: qbChartTitle[index],
                                    backgroundColor: "rgb(255, 0, 0)",
                                    borderColor: "rgb(255, 0, 0)",
                                    data: avArr,
                                    radius: 0,
                                    hitRadius: 5
                                },
                            ],
                            options: {
                                plugins: {
                                    tooltip: {
                                        callbacks: {
                                            label: function(context) {
                                                
                                                let label = context.dataset.label || '';
                        
                                                if (label) {
                                                    label += ': ';
                                                }
                                                if (context.parsed.y !== null) {
                                                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                                                }
                                                return label;
                                            }
                                        }
                                    }
                                }
                            }
                        }]}))
                    // })
                // }
                    }
                }
            })
    // })
        } else {
            // this.props.history.push("/stats");
        }
    }

    // renderedData = () => {
    //     console.log(this.state.isQB)

    //     return (
    //         <div>
    //         {this.state.isQB && <Line data={this.state.data[0]} />}
            
    //     </div>
    //     )
    // }


           
    render() {
        let headerheight = 0
        if (document.getElementById('headerID')){
            headerheight = document.getElementById('headerID').clientHeight;
        }
        let chartType = ""
        let radarDataPoints = []
        let radarLabels = []
        let maxRadarPoint = 0
        if (this.state.data[0]) {
            radarDataPoints = this.state.data[0].averages
            maxRadarPoint = Math.max(radarDataPoints)
            radarLabels = this.state.data[0].averagesLabels
        }
        let radarOptions = {
            scales: {
                r: {
                    angleLines: {
                        display: true
                    },
                    suggestedMin: 0,
                    suggestedMax: maxRadarPoint
                }
            },
            plugins: {
                title: {
                    display: true,
                    text: 'Average Performance Last Year',
                    font: {
                        size: 20
                    }
                },
                subtitle: {
                    display: true,
                    text: "Last year's league average performance equals 1. Values greater than 1 means the player performed better than average."
                },
                legend: {
                    display: false
                },
            }
        }
        let radarData = {
            labels: radarLabels,
            datasets: [
              {
                label: "Performance",
                data: radarDataPoints,
                backgroundColor: 'rgba(30, 144, 255,0.2)',
                borderColor:'rgba(30, 144, 255,0.2)',
                borderWidth: 1,
              },
            ],
          };
        let radarSize = "80%"
        if (window.innerWidth > 600) {
            radarSize = "60%"
        }
        let renderedData = this.state.data.map((chart)=> {
            let tmp = chart.datasets[0].title
            let renderHeader = false
            let headerText = undefined
            if (tmp.includes("Week")) {
                if (chartType !== 'byweek') {
                    chartType = 'byweek'
                    renderHeader = true
                    headerText = "Performance by Week"
                }
                chartType = 'byweek'
            } else if (tmp.includes("Against")) {
                if (chartType !== 'byopp') {
                    chartType = 'byopp'
                    renderHeader = true
                    headerText = "Performanc by Opponent"
                }
                chartType = 'byopp'
            } else if (tmp.includes("Home") || tmp.includes("Road")) {
                if (chartType !== 'byhoa') {
                    chartType = 'byhoa'
                    renderHeader = true
                    headerText = "Performance by Location"
                }
                chartType = 'byhoa'
            } else if (tmp.includes("doors")) {
                if (chartType !== 'byino') {
                    chartType = 'byino'
                    renderHeader = true
                    headerText = "Performance by Environment"
                }
                chartType = 'byino'
            } else {
                if (chartType !== 'base') {
                    chartType = 'base'
                    renderHeader = true
                    headerText = "Overall Performance Metrics"
                                    
                                
                }
                chartType = 'base'
            }
            // console.log(chart.charttype)
            let options = {maintainAspectRatio: false, 
                           plugins:{
                            zoom: {
                                pan: {
                                  mode: "x",
                                  enabled: true
                                },
                                limits: {
                                  // axis limits
                                },
                                zoom: {
                                  mode: "x",
                                  wheel: {
                                    enabled: true,
                                  },
                                  pinch: {
                                    enabled: true
                                  },
                                }
                              },
                                title: {
                                    display: true,
                                    text: chart.datasets[0].title
                                },
                                legend:{
                                    display:false
                                },
                                tooltip: {
                                    displayColors: false,
                                    position: "nearest",
                                    callbacks: {
                                        label: function (context, d) {
                                            if (context.datasetIndex === 0) {
                                            // function(context) {
                                                let label = context.dataset.label || '';
                                                if (context.parsed.y !== null) {
                                                    label += chart.matchup[context.dataIndex] + ": " + context.parsed.y;
                                                }
                                                return label;
                                            // }
                                            } else {
                                                return "AVG: " + context.parsed.y
                                            }
                                        }
                                    }
                                }
                            }
                           }
            return (
                    <div key={chart.datasets[0].title} style={{height:"fit-content",display: "contents"}}>
                        {renderHeader && <div  className = "container" style={{width:'100%'}}>
                            <div  style={{width:'100%',height:"fit-content"}}>
                                <h3 className="col s12 center-align" style={{fontFamily: "Gruppo"}}>{headerText}</h3>
                                <div className="divider" style={{width:'100%'}}></div>
                            </div>
                        </div>}
                        <div  className="col s10 m6" >
                            {<Line data={chart} height={"300px"} options={options} />}    
                        </div>
                    </div>
                    )
        } )
        return(
            <div className="" style={{width: "100%"}}>
                {/* <div className="row" > */}
                
                    <div className=""  style={{width: "100%",fontFamily: "Gruppo",position: "sticky", top: `${headerheight}px`, margin: "0px",backgroundColor: "white"}}>
                        {/* <div className="row" style={{width:"100%"}}> */}
                            {/* <button onClick={()=>this.props.history.push("/stats")} className="col s2 left-align center-align blue white-text" style={{borderRadius : "20px", overflow:"hidden", padding: "5px 30px", border: "none", cursor:"pointer", fontFamily:"Gruppo"}}>Go Back</button> */}
                            {/* <div className="container"> */}
                            <div className="row" style={{width:"100%",margin: "0px",padding: "0px"}}>
                                <div className="col s4 m2">
                                    <Link 
                                        to='/stats' 
                                        style={{fontFamily: "Gruppo",fontSize: window.innerWidth > 800 ? "22px" : '16px'}}
                                        >
                                        Go Back
                                    </Link>
                                </div>
                                <div className="col s12 center-align">
                                    <h1  style={{fontSize: window.innerWidth > 800 ? '' : '22px', fontFamily: "Gruppo",margin: "0px", width: "100%"}}>{this.state.reportName}</h1>
                                </div>
                            </div>
                            {/* </div> */}
                            <div className="col s12 divider" style={{width:'100%'}}></div>
                        {/* </div> */}
                    </div>
                    <div  className="center-align" style={{width: radarSize, margin: "0 auto"}}>
                        <Radar data={radarData} options={radarOptions}/>
                    </div>
                    <div className="row" style={{height:"fit-content"}}>
                            {renderedData}
                    </div>
                {/* </div> */}
            </div>
        )  
    }
}

function mapStateToProps(state) {
    return {stats: state.stats,searchPlayer: state.searchPlayer, auth: state.auth}
}

export default withRouter(connect(mapStateToProps, {fetchPlayers, fetchName, setSearchPlayer})(Report))