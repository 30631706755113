import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Select from 'react-select'

class Header extends Component {
    state = {
        selectedOption: null
      };
      
    
      handleChange = selectedOption => {
        // console.log(selectedOption)
        // const history = useHistory();
        // this.setState({ selectedOption });
        if (selectedOption.value === 'logout') {
            // axios.get('api/logout')
            let logout = document.getElementById("logoutbtn")
            logout.click()
            // history.push("/");
            // this.props.history.push('/stats')
        } else if (selectedOption.value === 'myreport') {
            // axios.get('api/logout')
            let myreports = document.getElementById("myreportsbtn")
            myreports.click()
            // history.push("/");
            // this.props.history.push('/stats')
        } else if (selectedOption.value === 'buycredits') {
            // axios.get('api/logout')
            let buycredits = document.getElementById("buycreditsbtn")
            buycredits.click()
            // history.push("/");
            // this.props.history.push('/stats')buycreditsbtn
        }
      };



    renderContent() {
        const options = [
            { value: "myreport", label: "My Reports" },
            { value: "logout", label: "Logout" }
          ];
        const mobileOptions = [
            { value: "myreport", label: "My Reports" },  
          ];
          let nc = 0
          try {
            nc = this.props.auth.credits
          } catch {
            nc = 0
          }
          if (nc > 100) {
            mobileOptions.push({value: "seasonpassholder", label: "Season Pass Holder"})
          } else {
            mobileOptions.push({value: "buycredits", label: "Buy Credits"})
            mobileOptions.push({value: "credits", label: `Credits: ${nc}`})
          }
          mobileOptions.push({ value: "logout", label: "Logout" })
          const customStyles = {
            control: (provided, state) => ({
              ...provided,
              border: 0,
              boxShadow: 'none',
              width: '130px',
              fontFamily: "Gruppo",
              fontSize: "17px",
              margin: "-2px 0px 0px 0px"
            }),
            option: (provided, state) => ({
                ...provided,
                border: 'none',
                fontFamily: "Gruppo",
                fontSize: "17px"
              }),
              
          };
          const { selectedOption } = this.state;
        switch (this.props.auth) {
            case null:
                return;
            case false:
                return (
                    <li>
                        {/* <a href="auth/google" className="black-text" style={{fontFamily: "Gruppo",fontSize: "17px"}}>Login with Google</a> */}
                        <button className='white' style={{margin: '17px 10px 10px 10px', width: '230px'}}>
                            <a href='auth/google' className="white black-text" >
                            <div className="valign-wrapper" style={{padding: '10px'}}>
                                <img  style={{width: '1.5rem', height: '1.5rem' }} src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
                                <span style={{paddingLeft: '5px',fontFamily: "Roboto"}}>Sign In with Google</span>
                            </div>
                            </a>
                        </button>
                    </li>
                )
            default:
                let headerArr = []
                // console.log(window.innerWidth)
                if (window.innerWidth > 600){
                    if (this.props.auth.credits > 100) {
                        headerArr.push(<li key="3" className="black-text" style={{margin: '0 10px',fontFamily: "Gruppo",fontSize: "17px"}}>Season Pass Holder</li>)
                    } else {
                        headerArr.push(<li key="1"><Link className="blue-text" to="/pricing" style={{fontFamily: "Gruppo",fontSize: "17px"}}>Buy Credits</Link></li>)
                        headerArr.push(<li key="3" className="black-text" style={{margin: '0 10px',fontFamily: "Gruppo",fontSize: "17px"}}>Credits: {this.props.auth.credits}</li>)
                    }
                    // headerArr.push(<li key="4"><a className="black-text"  href="api/logout" style={{fontFamily: "Gruppo",fontSize: "17px"}}>Logout</a></li>)
                    headerArr.push(<li key="5"><Select  value={selectedOption}
                    options={options}
                    onChange={this.handleChange}
                    isSearchable={false}
                    styles={customStyles}
                    placeholder={<div className="col black-text right" style={{height: "100%" , fontSize: "17px"}} >Account</div>} 
                    fontFamily="Gruppo"
                    blurInputOnSelect={true}
                    closeMenuOnSelect={false} menuPortalTarget={document.body} 
                    components={{
                        IndicatorSeparator: () => null,
                    }}
                    /></li>)
                } else {
                    headerArr.push(<li key="6"><Select  value={selectedOption}
                    options={mobileOptions}
                    onChange={this.handleChange}
                    isSearchable={false}
                    styles={customStyles}
                    placeholder={<div className="col black-text right" style={{height: "100%" , fontSize: "17px"}} >Account</div>} 
                    fontFamily="Gruppo"
                    blurInputOnSelect={true}
                    closeMenuOnSelect={false} menuPortalTarget={document.body} 
                    components={{
                        IndicatorSeparator: () => null,
                    }}
                    /></li>)
                }
                return headerArr
        }
    }

    render() {
        

        return (
            <nav id="headerID" className="z-depth-0 " style={{width:"100%",position: "relative", left: "0%"}}>
                
                <div className="nav-wrapper white " style={{padding: "0 0%"}}>
                
                    <Link 
                        to={this.props.auth ? '/stats' : '/'} 
                        style={{fontFamily: "Gruppo",fontSize: "40px"}}
                        className="left brand-logo black-text">
                        //stats
                    </Link>
                    <a id="logoutbtn" style={{display: "none"}} className="black-text"  href="api/logout"></a>
                    <a id="myreportsbtn" style={{display: "none"}} className="black-text"  href="/myreports"></a>
                    <a id="buycreditsbtn" style={{display: "none"}} className="black-text"  href="/pricing"></a>
                    <ul className="right" style={{overflow: "visible", padding: "0 0%",margin: "0"}}>
                        {this.renderContent()}
                        
                    </ul>
                </div>
            </nav>
        )
    }
}

function mapStateToProps({auth}) {
    return { auth };
}

export default connect(mapStateToProps)(Header);