const lastYearAverages = {
    'Passing TDs': 0.65, 
    'Passing Yards': 115.4,
    'Completions': 10.7,
    'Passing Interceptions': 0.46,
    'QBR': 83.52,
    "Rushing Attempts": 3.39,
    "Rushing Yards": 13.87,
    "Rushing TDs": 0.11,
    "Receiving Targets": 2.57,
    "Receiving Catches": 1.7,
    "Receiving Yards": 18.6,
    "Receiving TDs": 0.11
    }

    export default lastYearAverages