const dbTeamMap = {
    oti: "HOU",
    rav: "BAL",
    buf: "BUF",
    cin: "CIN",
    cle: "CLE",
    den: "DEN",
    clt: "IND",
    jax: "JAX",
    kan: "KAN",
    rai: "LVR",
    sdg: "LAC",
    mia: "MIA",
    nwe: "NWE",
    nyj: "NYJ",
    pit: "PIT",
    was: "WSH",
    tam: "TAM",
    sea: "SEA",
    sfo: "SFO",
    phi: "PHI",
    nyg: "NYG",
    nor: "NOR",
    min: "MIN",
    ram: "LAR",
    gnb: "GNB",
    det: "DET",
    dal: "DAL",
    chi: "CHI",
    car: "CAR",
    atl: "ATL",
    crd: "ARI"
    }

    export default dbTeamMap