import {Link} from 'react-router-dom';

const Footer = () => {

    return(
        <footer className="page-footer blue" style={{alignSelf: "flex-end", paddingTop:"0px"}}>
          <div className="" style={{padding: "0 10%"}}>
            <div className="row">
              <div className="col l6 s12">
                <h2 className="white-text" style={{fontFamily: "Gruppo"}}>//stats</h2>
                <Link to='/tou' className="white-text" style={{textDecorationLine: 'underline'}}>Terms of Use</Link>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link to='/privacy' className="white-text" style={{textDecorationLine: 'underline'}}>Privacy Policy</Link>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link to='/cookies' className="white-text" style={{textDecorationLine: 'underline'}}>Cookies Policy</Link>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="mailto:admin@ssstats.com" className='white-text' style={{textDecorationLine: 'underline'}}>Contact Us</a>
                <p className="grey-text text-lighten-4">Thank you for visiting.</p>
                
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <div className="" style={{width: "100%", padding: "0 10%"}}>
              <label className="right white-text">© //stats 2023</label>
            </div>
          </div>
        </footer>
    )
}


export default Footer;