import { FETCH_PLAYERNAME, FETCH_PLAYERS } from "../actions/types";
import _ from 'lodash'

export default function(state=[],action) {
    switch (action.type) {
        case FETCH_PLAYERS:
            const searchdataDisp={}
            const searchdata={}
            const reportdata={}
            _.forEach(action.payload, playr => {
                searchdataDisp[""+playr.name + " - " + playr.pos]= null
                // let tmp = {}
                // tmp['id']=playr._id
                // tmp['text']=""+playr.name + " - " + playr.pos
                searchdata[""+playr.name + " - " + playr.pos]=playr._id
                // searchdata.push(tmp)
                reportdata[playr._id]=""+playr.name + " - " + playr.pos
            })
            const masterdata=[searchdataDisp,searchdata,reportdata]
            return masterdata || false;
        case FETCH_PLAYERNAME:
            return action.payload || false;
        default:
            return state;
    }
}