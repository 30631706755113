import React, {Component} from 'react';
import {BrowserRouter,Route} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../actions'
import Header from './Header';
import Landing from './Landing';
import Pricing from './Pricing';
import Report from './Report';
import Footer from './Footer';
import MyReports from './MyReports';
import TOU from './TOU';
import Cookies from './Cookies';
import Privacy from './Privacy';
import ComparisonReport from './ComparisonReport';

class App extends Component {
    componentDidMount() {
        this.props.fetchUser();
    }

    render() {
        return (
            <div className="" style={{ position: "relative" }}>
                <BrowserRouter style={{}}>
                    <div className="" style={{ display: "flex",alignItems: "center",justifyContent: 'center', minHeight: "100vh",flexDirection: "column"}} >
                        <header style={{width: "100%",position: "sticky", top: "0px",zIndex:"1000", padding: "0 10%"}}>
                            <Header />
                        </header>
                        <main className="" style={{flex: "1 0 auto",width: "100%", padding: "0 10%"}}>
                            <Route exact path="/" component={Landing} />
                            <Route exact path="/pricing" component={Pricing} />
                            <Route exact path="/stats" component={Landing} />
                            <Route exact path="/report" component={Report} />
                            <Route exact path="/myreports" component={MyReports} />
                            <Route exact path="/compreport" component={ComparisonReport} />
                            <Route exact path="/tou" component={TOU} />
                            <Route exact path="/cookies" component={Cookies} />
                            <Route exact path="/privacy" component={Privacy} />
                        </main>
                        <footer style={{width: "100%"}}>
                            <Footer style={{alignSelf: "flex-end"}}/>
                        </footer>
                    </div>
                </BrowserRouter>
            </div>
        )
    }
}



export default connect(null, actions)(App);