import { combineReducers } from "redux";
import authReducer from "./authReducer";
import statsReducer from "./statsReducer";
import statsReducerA from "./statsReducerA";
import statsReducerB from "./statsReducerB";
import playersReducer from "./playersReducer";
import red from "./red";
import redA from "./redA";
import redB from "./redB";
import {reducer as reduxForm} from 'redux-form';
import searchPlayerReducer from "./searchPlayerReducer";
import myReportsReducer from "./myReportsReducer";
import searchPlayerReducerA from './searchPlayerReducerA'
import searchPlayerReducerB from './searchPlayerReducerB'

export default combineReducers({
    auth: authReducer,
    form: reduxForm,
    stats: statsReducer,
    statsA: statsReducerA,
    statsB: statsReducerB,
    players: playersReducer,
    searchPlayer: searchPlayerReducer,
    searchPlayerA: searchPlayerReducerA,
    searchPlayerB: searchPlayerReducerB,
    red,
    redA,
    redB,
    myreports: myReportsReducer
})
