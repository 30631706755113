import React, { Component } from "react";
import M from "materialize-css";
import { connect } from "react-redux";
import { autoCompleteChangedB } from "../actions";
import { fetchPlayers, setSearchPlayerB, fetchStatsB } from '../actions';

class AutocompleteB extends Component  {
  async componentDidMount() {
    this.props.autoCompleteChangedB("");
    await this.props.fetchPlayers();
    const pids = this.props.players[1]
    const searchpid=this.props.setSearchPlayerB;
    const getstats=this.props.fetchStatsB;
    const searchChanged = this.props.autoCompleteChangedB;
    const options = {
      //Data object for autocomplete
      
      data: this.props.players[0],
    //   data: {
    //     Apple: null,
    //     Acer: null,
    //     Alcatel: null,
    //     Asus: null,
    //     Huawei: null,
    //     HTC: null,
    //     Karbonn: null,
    //     Lenovo: null,
    //     Lava: null,
    //     LG: null,
    //     Microsoft: null,
    //     Meizu: null,
    //     Nokia: null,
    //     Oppo: null,
    //     OnePlus: null,
    //     Sony: null,
    //     Vivo: null,
    //     Yu: null,
    //     Google: "https://placehold.it/250x250"
    //   },

      //Limit of results autocomplete shows
      limit: 5,

      //Callback function for Autocomplete
      onAutocomplete() {
        
        let input = document.getElementById("autocomplete-inputb");
        searchpid(pids[input.value])
        getstats(pids[input.value])
        searchChanged(input.value)
      },

      //Minimum number of characters before autocomplete starts.
      minLength: 1
    };
    M.Autocomplete.init(this.AutocompleteB, options);

    //Instance Plugin
    //let instance = M.Autocomplete.getInstance(this.Autocomplete);

    // instance.open();
    // instance.updateData({
    //   "Nothing": null
    // });
    // instance.destroy();
  }

  onAutoCompleteChange = event => {
    this.props.autoCompleteChangedB(event.target.value);
  };



  render() {
    //console.log(this.props.autoCompleteValue);
    return (
      <div>
        <div className="">
          <div className="">
            <div className="">
              <div className="input-field">
                <input onFocus={(e) => e.target.value = ""}
                  placeholder="Search Players..."
                  style={{fontFamily: "Gruppo"}}
                  autoComplete="off"
                  ref={AutocompleteB => {
                    this.AutocompleteB = AutocompleteB;
                  }}
                  type="text"
                  id="autocomplete-inputb"
                  className="autocomplete"
                  onChange={this.onAutoCompleteChange}
                  value={this.props.autoCompleteValueB}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  autoCompleteValueB: state.redB.autoCompleteValueB,
  players: state.players,
  searchPlayerB: state.searchPlayerB
});

export default connect(
  mapStateToProps,
  { autoCompleteChangedB, fetchPlayers, setSearchPlayerB, fetchStatsB }
)(AutocompleteB);
