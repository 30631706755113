const scheduleOpponents = {
    ARI:["@BUF","LAR","DET","WSH","@SFO","@GNB","LAC","@MIA","CHI","NYJ","BYE","@SEA","@MIN","SEA","NWE","@CAR","@LAR","SFO"],
    ATL:["PIT","@PHI","KAN","NOR","TAM","@CAR","SEA","@TAM","DAL","@NOR","@DEN","BYE","LAC","@MIN","@LVR","NYG","@WSH","CAR"],
    BAL:["@KAN","LVR","@DAL","BUF","@CIN","WSH","@TAM","@CLE","DEN","CIN","@PIT","@LAC","PHI","BYE","@NYG","PIT","@HOU","CLE"],
    BUF:["ARI","@MIA","JAX","@BAL","@HOU","@NYJ","TEN","@SEA","MIA","@IND","KAN","BYE","SFO","@LAR","@DET","NWE","NYJ","@NWE"],
    CAR:["@NOR","LAC","@LVR","CIN","@CHI","ATL","@WSH","@DEN","NOR","NYG","BYE","KAN","TAM","@PHI","DAL","ARI","@TAM","@ATL"],
    CHI:["TEN","@HOU","@IND","LAR","CAR","JAX","BYE","@WSH","@ARI","NWE","GNB","MIN","@DET","@SFO","@MIN","DET","SEA","@GNB"],
    CIN:["NWE","@KAN","WSH","@CAR","BAL","@NYG","@CLE","PHI","LVR","@BAL","@LAC","BYE","PIT","@DAL","@TEN","CLE","DEN","@PIT"],
    CLE:["DAL","@JAX","NYG","@LVR","@WSH","@PHI","CIN","BAL","LAC","BYE","@NOR","PIT","@DEN","@PIT","KAN","@CIN","MIA","@BAL"],
    DAL:["@CLE","NOR","BAL","@NYG","@PIT","DET","BYE","@SFO","@ATL","PHI","HOU","@WSH","NYG","CIN","@CAR","TAM","@PHI","WSH"],
    DEN:["@SEA","PIT","@TAM","@NYJ","LVR","LAC","@NOR","CAR","@BAL","@KAN","ATL","@LVR","CLE","BYE","IND","@LAC","@CIN","KAN"],
    DET:["LAR","TAM","@ARI","SEA","BYE","@DAL","@MIN","TEN","@GNB","@HOU","JAX","@IND","CHI","GNB","BUF","@CHI","@SFO","MIN"],
    GNB:["@PHI","IND","@TEN","MIN","@LAR","ARI","HOU","@JAX","DET","BYE","@CHI","SFO","MIA","@DET","@SEA","NOR","@MIN","CHI"],
    HOU:["@IND","CHI","@MIN","JAX","BUF","@NWE","@GNB","IND","@NYJ","DET","@DAL","TEN","@JAX","BYE","MIA","@KAN","BAL","@TEN"],
    IND:["HOU","@GNB","CHI","PIT","@JAX","@TEN","MIA","@HOU","@MIN","BUF","@NYJ","DET","@NWE","BYE","@DEN","TEN","@NYG","JAX"],
    JAX:["@MIA","CLE","@BUF","@HOU","IND","@CHI","NWE","GNB","@PHI","MIN","@DET","BYE","HOU","@TEN","NYJ","@LVR","TEN","@IND"],
    KAN:["BAL","CIN","@ATL","@LAC","NOR","BYE","@SFO","@LVR","TAM","DEN","@BUF","@CAR","LVR","LAC","@CLE","HOU","@PIT","@DEN"],
    LVR:["@LAC","@BAL","CAR","CLE","@DEN","PIT","@LAR","KAN","@CIN","BYE","@MIA","DEN","@KAN","@TAM","ATL","JAX","@NOR","LAC"],
    LAR:["@DET","@ARI","SFO","@CHI","GNB","BYE","LVR","MIN","@SEA","MIA","@NWE","PHI","@NOR","BUF","@SFO","@NYJ","ARI","SEA"],
    LAC:["LVR","@CAR","@PIT","KAN","BYE","@DEN","@ARI","NOR","@CLE","TEN","CIN","BAL","@ATL","@KAN","TAM","DEN","@NWE","@LVR"],
    MIA:["JAX","BUF","@SEA","TEN","@NWE","BYE","@IND","ARI","@BUF","@LAR","LVR","NWE","@GNB","NYJ","@HOU","SFO","@CLE","@NYJ"],
    MIN:["@NYG","SFO","HOU","@GNB","NYJ","BYE","DET","@LAR","IND","@JAX","@TEN","@CHI","ARI","ATL","CHI","@SEA","GNB","@DET"],
    NWE:["@CIN","SEA","@NYJ","@SFO","MIA","HOU","@JAX","NYJ","@TEN","@CHI","LAR","@MIA","IND","BYE","@ARI","@BUF","LAC","BUF"],
    NOR:["CAR","@DAL","PHI","@ATL","@KAN","TAM","DEN","@LAC","@CAR","ATL","CLE","BYE","LAR","@NYG","WSH","@GNB","LVR","@TAM"],
    NYG:["MIN","@WSH","@CLE","DAL","@SEA","CIN","PHI","@PIT","WSH","@CAR","BYE","TAM","@DAL","NOR","BAL","@ATL","IND","@PHI"],
    NYJ:["@SFO","@TEN","NWE","DEN","@MIN","BUF","@PIT","@NWE","HOU","@ARI","IND","BYE","SEA","@MIA","@JAX","LAR","@BUF","MIA"],
    PHI:["GNB","ATL","@NOR","@TAM","BYE","CLE","@NYG","@CIN","JAX","@DAL","WSH","@LAR","@BAL","CAR","PIT","@WSH","DAL","NYG"],
    PIT:["@ATL","@DEN","LAC","@IND","DAL","@LVR","NYJ","NYG","BYE","@WSH","BAL","@CLE","@CIN","CLE","@PHI","@BAL","KAN","CIN"],
    SFO:["NYJ","@MIN","@LAR","NWE","ARI","@SEA","KAN","DAL","BYE","@TAM","SEA","@GNB","@BUF","CHI","LAR","@MIA","DET","@ARI"],
    SEA:["DEN","@NWE","MIA","@DET","NYG","SFO","@ATL","BUF","LAR","BYE","@SFO","ARI","@NYJ","@ARI","GNB","MIN","@CHI","@LAR"],
    TAM:["WSH","@DET","DEN","PHI","@ATL","@NOR","BAL","ATL","@KAN","SFO","BYE","@NYG","@CAR","LVR","@LAC","@DAL","CAR","NOR"],
    TEN:["@CHI","NYJ","GNB","@MIA","BYE","IND","@BUF","@DET","NWE","@LAC","MIN","@HOU","@WSH","JAX","CIN","@IND","@JAX","HOU"],
    WSH:["@TAM","NYG","@CIN","@ARI","CLE","@BAL","CAR","CHI","@NYG","PIT","@PHI","DAL","TEN","BYE","@NOR","PHI","ATL","@DAL"]
    }

    export default scheduleOpponents