import { AUTOCOMPLETE_CHANGED_A } from "../actions/types";

const initial_state = {
  autoCompleteValueA: ""
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case AUTOCOMPLETE_CHANGED_A:
      return { ...state, autoCompleteValueA: action.autoCompleteValueA };
    default:
      return { ...state };
  }
};
