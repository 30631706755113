
const headerMap = {
name: "Name",
id: "ID",
team: "Team",
opponent: "Opponent",
year: "Year",
week: "Week",
final: "Final",
hoa: "Place",
time: "Time",
for_head_coach: "Opposing HC",
opp_head_coach: "Head Coach",
for_off_coord: "Offensive Coordinator",
opp_off_coord: "Opposing OC",
for_def_coord: "Defensive Coordinator",
opp_def_coord: "Opposing DC",
location: "Environment",
temperature: "Temperature",
humidity: "Humidity",
wind: "Wind",
pass_cmp: "Pass Completions",
pass_att: "Pass Attempts",
pass_yds: "Passing Yards",
pass_td: "Passing TDs",
pass_int: "INTs",
pass_sacked: "Sacked",
pass_sacked_yds: "Sack Yards",
pass_long: "Longest Pass",
pass_rating: "QBR",
rush_att: "Rush Attempts",
rush_yds: "Rushing Yards",
rush_td: "Rushing TDs",
rush_long: "Longest Rush",
targets: "Targets",
rec: "Receptions",
rec_yds: "Receiving Yards",
rec_td: "Receiving TDs",
rec_long: "Longest Catch",
fumbles: "Fumbles",
fumbles_lost: "Fumbles Lost",
def_int: "Def INTs",
def_int_yds: "INT Yards",
def_int_td: "INT TDs",
def_int_long: "Longest INT",
pass_defended: "Passes Defended",
sacks: "Sacks",
tackles_combined: "Total Tackles",
tackles_solo: "Solo Tackles",
tackles_assists: "Tackle Assists",
tackles_loss: "Tackles for Loss",
qb_hits: "QB Hits",
fumbles_rec: "Fumbles Recovered",
fumbles_rec_yds: "Fumble Return Yards",
fumbles_rec_td: "Fumble Return TDs",
fumbles_forced: "Forced Fumbles",
xpm: "Extra Points Made",
xpa: "Extra Points Attempted",
fgm: "Field Goals Made",
fga: "Field Goals Attempted",
punt: "Punts",
punt_yds: "Punt Yards",
punt_yds_per_punt: "Yards per Punt",
punt_long: "Longest Punt",
};

export default headerMap;