import React, {Component} from 'react';
import {connect} from 'react-redux';
import { fetchStats,fetchReports,setSearchPlayer, reduceCredits, reduceCreditsComp } from '../actions';
import _ from 'lodash';
import Table from './Table';
import headerMap from '../utils/headerMap';
import PlayerSearch from './PlayerSearch';
import PlayerSearchA from './PlayerSearchA';
import PlayerSearchB from  './PlayerSearchB';
import getCurrentWeek from '../utils/getCurrentWeek';
import M from 'materialize-css';
import { withRouter } from "react-router-dom";
import toolTipMap from '../utils/toolTipMap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'; // eslint-disable-line
import 'react-tabs/style/react-tabs.css';

class Landing extends Component {

    state = {
        firstRender: false,
        loadingStats: false,
        searchname: ''
    }

    componentDidMount() {
        // this.props.setSearchPlayer('')
        // console.log('0')
        // this.props.fetchStats('')
        // this.state.firstRender = false
        this.setState({firstRender : false})
        this.setState({loadingStats: false})
        
    }

    componentDidUpdate( prevProps,  prevState){
        // console.log(this.state.loadingStats)
        // if (prevProps.stats !== this.props.stats & this.props.stats.length > 0) {
        //     this.setState({loadingStats: false})
        // } else if (prevProps.stats !== this.props.stats) {
        //     // this.setState({loadingStats: true})
        // }
        try {
            let input = document.getElementById("autocomplete-input");
            // console.log(input?.value.length)
            if (input.value.length === 1) {
                // this.setState({loadingStats: true})
                this.setState({firstRender : false})
            }
            let tmp = input.value.split(' ')
            // console.log(this.state.searchname)
            if (tmp[tmp.length - 2] === "-" & this.state.searchname !== input.value){
                this.setState({searchname: input.value})
                this.setState({loadingStats: true})
                // this.state.firstRender = true
                // this.setState({firstRender : true})
                // if (this.props.stats.length > 0) {
                    // this.setState({loadingStats: false})
                // }
            }
            if (this.props.stats !== prevProps.stats & this.props.stats.length > 0) {
                this.setState({loadingStats: false})
                this.setState({firstRender : true})
            }
            
        } catch {
            // console.log('comp page')
            
        }
    }

     searchStats = () => {
        console.log('searching')
        this.setState({loadingStats: true})
        this.props.fetchStats(this.props.searchPlayer)
        // this.state.firstRender = true
        this.setState({firstRender : true})
        // console.log(this.state.firstRender)
    }

     keyFn = (data) => {
        return "" + data.year + data.week;
    }


     createReport = async (event) => {
        if (!this.props.auth) {
            this.props.setSearchPlayer('AlleJo02')
            await this.props.fetchStats('AlleJo02')
            M.toast({html: 'Log in to create a different report'})
            this.props.history.push("/report");
        } else {
            await this.props.fetchReports()
            // logged in, reduce credits
            if (this.props.auth.credits < 1) {
                if (!this.props.auth.reports.includes(this.props.searchPlayer)){
                event.preventDefault()
                M.toast({html: 'Please add more credits to create a new report'})
            }
            } else {
                let currentWeek = getCurrentWeek()
                if (this.props.searchPlayer !== "AlleJo02") {
                    this.props.reduceCredits(this.props.searchPlayer, currentWeek)
                }
                this.props.history.push("/report");
            }
        }
    }

    handleSelect = (index,lastIndex,event) => {
        if (index === 1) {
            // this.state.firstRender = false
            this.setState({firstRender : false})
            this.forceUpdate()
        }
    }

    createComparisonReport = async (event) => {
        await this.props.fetchReports()
        if (this.props.auth.credits < 1) {
            if (!this.props.auth.reports.includes(this.props.searchPlayerA) && !this.props.auth.reports.includes(this.props.searchPlayerB)){
                event.preventDefault()
                M.toast({html: 'Please add more credits to create a new report'})
            }
        }else if (this.props.auth.credits === 1) {
            if (!(this.props.auth.reports.includes(this.props.searchPlayerA) || this.props.auth.reports.includes(this.props.searchPlayerB))){
                event.preventDefault()
                M.toast({html: 'Please add more credits to create a new report'})
            }
        } else {
            let currentWeek = getCurrentWeek()
            this.props.reduceCreditsComp(this.props.searchPlayerA,this.props.searchPlayerB, currentWeek)
            this.props.history.push("/compreport");
        }

        
    }

     renderedData = () => {
        let emptyColumns = [];
        let validColumns = [];
        let config=[]
        let ngames = this.props.stats.length
        if (ngames > 0){
            let allColumnNames = Object.keys(this.props.stats[0])
            _.forEach(allColumnNames, stat => {
                let tmp = 0
                _.forEach(this.props.stats, (value,key) => {
                    if (value[stat] === "0" || value[stat] === "0.0" || value[stat] === "") {
                        tmp += 1
                    }
                })
                if (tmp===ngames){
                    emptyColumns.push(stat)
                } else {
                    validColumns.push(stat)
                }
            })

            emptyColumns.push("_id")
            emptyColumns.push("id")
            emptyColumns.push("name")
            emptyColumns.push("team")
            emptyColumns.push("final")
            let forDeletion = ["_id","id","name","team","final"]
            validColumns = validColumns.filter(item => !forDeletion.includes(item))
            
            _.forEach(validColumns, stat => {
                config.push({label: headerMap[stat], render: (obj)=>obj[stat]})
            })

            _.forEach(this.props.stats, (value,key) => {
                _.forEach(emptyColumns, col => {
                    delete value[col]
                })
                if (value["year"] <= 2021) {
                    let x = value["for_head_coach"]
                    value["for_head_coach"]=value["opp_head_coach"]
                    value["opp_head_coach"] = x
                }
            })

            var element = config[0];
            config.splice(0, 1);
            config.splice(2, 0, element);

            [config[5], config[6]] = [config[6], config[5]];

            _.forEach(this.props.stats, (value,key) => {
                // console.log(value)
                value["opponent"]=toolTipMap[value.opponent]
            })


            

        } 
        

        // let input = document.getElementById("autocomplete-input");
        //     console.log(input?.value.length)
        //     if (input?.value.length > 0) {
        //         this.setState({loadingStats: true})
        //     }



        return (
                    <div className="" style={{width:"100%", position: "relative", margin: "0px 0px"}}>
                        <Tabs onSelect={this.handleSelect}>
                            <TabList>
                                <Tab style={{fontFamily: "Gruppo"}}>Single Report</Tab>
                                <Tab disabled={!this.props.auth ? true : false} style={{fontFamily: "Gruppo"}}>Comparison Report</Tab>
                            </TabList>
                            <TabPanel>
                                <br />
                                <div className='row valign-wrapper ' style={{position: 'center', width:"100%"}}>
                                    <div className="col s8 m10">
                                        <PlayerSearch  id="searchbox"/>   
                                    </div>
                                    <div className="col s4 m2">
                                        <button onClick={this.searchStats} className="blue white-text" style={{borderRadius : "20px", overflow:"hidden", padding: "5px 30px", border: "none", cursor:"pointer", fontFamily:"Gruppo"}}>Search</button>
                                    </div>
                                </div>
                                <div className="row center-align" style={{ marginTop: "10px", fontFamily: "Gruppo" }}>
                                    {/* <Button  onClick={this.createReport} to="/report" condition={this.props.stats.length > parseInt(Boolean(this.props.auth)+true-true-1)}> */}
                                    <button className="blue white-text" onClick={this.createReport} style={{borderRadius : "20px", overflow:"hidden", padding: "5px 30px", border: "none", cursor:"pointer", fontFamily: "Gruppo"}}>    
                                        {this.props.auth ? "Generate Report": "Generate Sample Report"}
                                    </button>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <br />
                                <div className='row' style={{width:"100%"}}>
                                    <div className="col s6">
                                        <PlayerSearchA id="searchboxA"/>   
                                    </div>
                                    <div className="col s6">
                                        <PlayerSearchB id="searchboxB"/>   
                                    </div>
                                </div>
                                <div className="row center-align" style={{ marginTop: "10px", fontFamily: "Gruppo" }}>
                                    {/* <Button  onClick={this.createReport} to="/report" condition={this.props.stats.length > parseInt(Boolean(this.props.auth)+true-true-1)}> */}
                                    <button className="blue white-text" onClick={this.createComparisonReport} style={{borderRadius : "20px", overflow:"hidden", padding: "5px 30px", border: "none", cursor:"pointer", fontFamily: "Gruppo"}}>    
                                        {this.props.auth ? "Generate Comparison Report": "Generate Comparison Report"}
                                    </button>
                                </div>
                                    
                               
                            </TabPanel>
                        </Tabs>
                        
                        
                        <div className="divider"></div>

                        {this.state.loadingStats
                            &&
                                <div style={{display: 'flex', justifyContent: 'center',marginTop: '20px'}}>
                                <div className="preloader-wrapper big active">
                                    <div className="spinner-layer spinner-blue-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div><div className="gap-patch">
                                        <div className="circle"></div>
                                    </div><div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                }
                        
                        {this.state.firstRender && <Table data={this.props.stats} config={config} keyFn={this.keyFn}/>}
                    </div>
        )
    }

    

    //

    render() {
        return (
            
                    this.renderedData()
        )
    }
}

function mapStateToProps(state) {
    return {stats: state.stats, 
            statsA: state.statsA, 
            statsB: state.statsB, 
            searchPlayer: state.searchPlayer, 
            searchPlayerA: state.searchPlayerA,
            searchPlayerB: state.searchPlayerB,
            auth: state.auth, 
            autoCompleteValue: state.red.autoCompleteValue,
            autoCompleteValueA: state.red.autoCompleteValueA,
            autoCompleteValueB: state.red.autoCompleteValueB
           }
}

export default withRouter(connect(mapStateToProps, {fetchReports,fetchStats,setSearchPlayer, reduceCredits,reduceCreditsComp})(Landing))