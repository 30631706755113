import React from 'react';
import "./component.css"

const Pricing = () => {
    return(
        <div className="container">
            <div>
                <h1 className="center-align" style={{fontFamily: "Gruppo"}}>Choose the plan that's right for you!</h1>
            </div>
            <div className="row">
            {/* <div className="container" style={{width:"100%"}}>
            <div className="row"> */}
                <div className="card hoverable large col s10 m5" style={{borderRadius : "15%", overflow:"hidden"}}>
                    
                    <div className="card-content">
                    <span className="card-title center-align" style={{fontFamily: "Gruppo"}}>Individual Report</span>
                        <h4 className="center-align">$1</h4>
                        <br></br>
                        <p>Try out a single report and see what everyone is talking about!</p>
                        
                            <ul >
                                <li style={{listStyleType: "initial",marginLeft:"30px"}}>Create a report for a single player</li>
                                <li style={{listStyleType: "initial",marginLeft:"30px"}}>Access new reports weekly for that player</li>
                            </ul>  

                    </div>
                    <div className="card-action center-align">
                        <form action="/create-checkout-session1" method="POST">
                            <button type="submit">Checkout</button>
                        </form>
                    </div>
                {/* </div>
            </div> */}
        </div>
            {/* <div className="container" style={{width:"100%"}}>
                <div className="row"> */}
                    <div className="card hoverable large col s10 m5 offset-m1" style={{borderRadius : "15%", overflow:"hidden"}}>
                        
                        <div className="card-content">
                        <span className="card-title center-align" style={{fontFamily: "Gruppo"}}>Season Pass</span>
                            <h4 className="center-align" >$5</h4>
                            <br></br>
                            <p>The option for serious contenders looking for every edge to bring home the chip.</p>
                            
                                <ul >
                                    <li style={{listStyleType: "initial",marginLeft:"30px"}}>Create an unlimited number of reports</li>
                                    <li style={{listStyleType: "initial",marginLeft:"30px"}}>Continous access to new reports throughout the season</li>
                                </ul>  

                        </div>
                        <div className="card-action center-align">
                        {/* <form action="http://localhost:5000/create-checkout-session5" method="POST"> */}
                            <form action="/create-checkout-session5" method="POST">
                                <button type="submit">Checkout</button>
                            </form>
                        </div>
                    </div>
                {/* </div>
            </div> */}
        </div>
        </div>

    )
}

export default Pricing