import React, {Component} from 'react';
import { fetchPlayers } from '../actions';
import {connect} from 'react-redux';
import AutocompleteA from './AutocompleteA';

class PlayerSearchA extends Component {
         componentDidMount() {
            // await this.props.fetchPlayers();
            let x = document.getElementById("sba")
            // console.log(this.props.players)
            // const searchdata=[]
            // _.forEach(this.props.players, playr => {
            //     let tmp = {}
            //     tmp['id']=playr._id
            //     tmp['text']=""+playr.name + " - " + playr.pos
            //     searchdata.push(tmp)
            // })
            // console.log(searchdata)
            // document.addEventListener('DOMContentLoaded', function() {
            //     var elems = document.querySelectorAll('.autocomplete');
            //     const options = {
            //         //Data object for autocomplete
            //         data: this.props.players,
              
            //         //Limit of results autocomplete shows
            //         limit: 5,
              
            //         //Callback function for Autocomplete
            //         onAutocomplete() {
            //           console.log("Completed");
            //           let input = document.getElementById("autocomplete-input");
            //           console.log(input.value);
            //         },
              
            //         //Minimum number of characters before autocomplete starts.
            //         minLength: 1
            //       };
            //     var instances = M.Autocomplete.init(elems, options);
            //   });

        }
           

    render() {
        return(
        <div>
            {/* <div className="row">
                <div className="col s12">
                    <div className="row"> */}
                        {/* <div > */}
                            <AutocompleteA id="sba" data={this.props.players} />
                        {/* </div> */}
                    {/* </div>
                </div>
            </div> */}
            
        </div>
        )  
    }
}

function mapStateToProps(state) {
    return {players: state.players}
}

export default connect(mapStateToProps, {fetchPlayers})(PlayerSearchA)