import React from 'react';
import {connect} from 'react-redux';
import { fetchReports, fetchPlayers, setSearchPlayer, fetchStats } from '../actions';
import {useEffect} from 'react';
import {Link} from 'react-router-dom';

const MyReports = ({ fetchReports, myreports, players, fetchPlayers, setSearchPlayer, searchPlayer, fetchStats, stats}) => {

    
    
    useEffect(() => {
        const getReports = async () => {
            await fetchReports();

        }
        getReports().catch(console.error);
        const getPlayers = async () => {
            await fetchPlayers();

        }
        getPlayers().catch(console.error);

        
    },[])

    useEffect(() => {
        if (searchPlayer.length > 0) {
            const getStats = async () => {
                await fetchStats(searchPlayer)
            }
            getStats().catch(console.error)
            
        }
    },[searchPlayer])

    useEffect(() => {
        if (stats.length > 0) {
            let reportbtn = document.getElementById("reportbtn")
            reportbtn.click()
        }
    },[stats])

    const handleClick = (pid) => {
        setSearchPlayer(pid)
        
    }

      let renderedData = ""
      if (myreports.length > 0 && players.length > 0) {
        renderedData  = myreports.map((player) => {
        return (
            <li key={player}><a onClick={() => handleClick(player)} style={{fontFamily: "Gruppo", fontSize: "20px"}} href="#">{players[2][player]}</a></li>
        )
      })
    } 

    return(
        <div>
            <h1 style={{fontFamily: "Gruppo"}}>My Reports</h1>
            <div className="divider"></div>
            <Link id="reportbtn" style={{display: "none"}} to="/report"></Link>
            <ul>
            {renderedData}
            </ul>
        </div>

    )
}

function mapStateToProps(state) {
    return {myreports: state.myreports, auth: state.auth, players: state.players, searchPlayer: state.searchPlayer, stats: state.stats}
}

export default connect(mapStateToProps,{fetchReports,fetchPlayers, setSearchPlayer, fetchStats})(MyReports)