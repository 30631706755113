import { AUTOCOMPLETE_CHANGED_B } from "../actions/types";

const initial_state = {
  autoCompleteValueB: ""
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case AUTOCOMPLETE_CHANGED_B:
      return { ...state, autoCompleteValueB: action.autoCompleteValueB };
    default:
      return { ...state };
  }
};
