const teamNameMap = {
    ARI:"Cardinals",
    ATL:"Falcons",
    BAL:"Ravens",
    BUF:"Bills",
    CAR:"Panthers",
    CHI:"Bears",
    CIN:"Bengals",
    CLE:"Browns",
    DAL:"Cowboys",
    DEN:"Broncos",
    DET:"Lions",
    GNB:"Packers",
    HOU:"Texans",
    IND:"Colts",
    JAX:"Jaguars",
    KAN:"Chiefs",
    LVR:"Raiders",
    LAR:"Rams",
    LAC:"Chargers",
    MIA:"Dolphins",
    MIN:"Vikings",
    NWE:"Patriots",
    NOR:"Saints",
    NYG:"Giants",
    NYJ:"Jets",
    PHI:"Eagles",
    PIT:"Steelers",
    SFO:"49ers",
    SEA:"Seahawks",
    TAM:"Bucs",
    TEN:"Titans",
    WSH:"Commanders",
    }

    export default teamNameMap