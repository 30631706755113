import React, {Component} from 'react';
import { fetchPlayers, fetchName , setSearchPlayer} from '../actions';
import {connect} from 'react-redux';
import _ from 'lodash';
import Chart from "chart.js/auto";
import zoomPlugin from 'chartjs-plugin-zoom';
import { Line } from "react-chartjs-2";
import { withRouter } from "react-router-dom";
import lastYearAverages from '../utils/lastYearAverages';
import { Radar } from 'react-chartjs-2';
import {Link} from 'react-router-dom';

class Report extends Component {

    state = {
        data: [],
        isQB: false,
        reportNameA: "",
        reportNameB: ""
    }
    // constructor(props) {
    //     super(props)

    //     this.state = {
    //         data: [],
    //         isQB: false
    //     }
    // }

    async componentDidMount() {
        Chart.register(zoomPlugin)
        const lastYear = 2022
        const thisYear = 2023
        if (this.props.statsA.length > 0){
            let playerA = await this.props.fetchName(this.props.searchPlayerA)
            let playerB = await this.props.fetchName(this.props.searchPlayerB)
            // x = {...x,
            //     team : dbTeamMap[x.team]}
            
                
            // this.props.setSearchPlayer("")
            let averages = []
            let averages2 = []
            let averagesLabels = []
            this.setState({reportNameA : playerA.name})
            this.setState({reportNameB : playerB.name})
            let stats = ["pass_td","pass_yds","pass_cmp","pass_int","pass_rating","rush_att","rush_yds","rush_td","targets","rec","rec_yds","rec_td","fga","fgm","xpa","xpm","def_int","sacks","tackles_combined","fumbles_forced"]
            let qbChartTitle=['Passing TDs', 'Passing Yards','Completions','Passing Interceptions','QBR',"Rushing Attempts","Rushing Yards","Rushing TDs","Receiving Targets","Receiving Catches","Receiving Yards","Receiving TDs","Field Goals Attempted","Field Goals Made","Extra Points Attempted","Extra Points Made","Interceptions","Sacks","Tackles","Forced Fumbles"]
            let mobileTitles = ['Pass TD','Pass Yds','Cmp','Int','QBR','Rushes','Rush Yds','Rush TDs','Rec','Tar','Catches','Rec Yds','Rec TDs']
            let skipLoop = false
         
            _.range(1,stats.length+1).forEach((current,index) => {
                let tmp = stats[index].split('_')
                // console.log(tmp)
                
                // console.log(chartType)
                let strCheck = ''
                if (tmp.length > 2) {
                    strCheck = tmp.slice(0,-1).join('_')
                } else {
                    if (tmp[tmp.length -1] === 'byweek' || tmp[tmp.length -1] === 'byopp' || tmp[tmp.length -1] === 'byhoa' || tmp[tmp.length -1] === 'byino'){
                        strCheck = tmp[0]
                    } else {
                        strCheck = stats[index]
                    }
                }
                if (this.props.statsA[0].hasOwnProperty(strCheck)){
                    let statsArr = []
                    let labels = []
                    let matchups = []
                    let statsArr2 = []
                    let labels2 = []
                    let matchups2 = []
                    let lasyYearArr = []
                    let lasyYearArr2 = []
                    skipLoop = false
                    _.forEach(this.props.statsA, game => {
                            statsArr.push(game[stats[index]])
                            if (game["year"]===lastYear || game["year"]===thisYear){
                                lasyYearArr.push(game[stats[index]])
                            }
                            // chartType = 'base'
                            labels.push(game["year"]+" - Week "+game["week"])
                            if (game["hoa"]==='AWAY'){
                                matchups.push("@"+game["opponent"])
                            } else {
                                matchups.push(game["opponent"])
                            }
                        
                    })
                    _.forEach(this.props.statsB, game => {
                        statsArr2.push(game[stats[index]])
                        if (game["year"]===lastYear || game["year"]===thisYear){
                            lasyYearArr2.push(game[stats[index]])
                        }
                        // chartType = 'base'
                        labels2.push(game["year"]+" - Week "+game["week"])
                        if (game["hoa"]==='AWAY'){
                            matchups2.push("@"+game["opponent"])
                        } else {
                            matchups2.push(game["opponent"])
                        }
                    
                    })
                    let labels1 = labels.slice(0)
                    let n2 = statsArr2.length
                    _.range(0,n2).forEach((current,index) => {
                        if (!labels.includes(labels2[index])) {
                            labels.push(labels2[index])
                        }
                    })
                    labels = labels.filter(function( element ) {
                        return element !== undefined;
                     });
                    let tmp = []
                    _.range(0,labels.length).forEach((current,index) => {
                        tmp = labels[index].split(' ')
                        if (tmp[tmp.length-1].length===1){
                            tmp[tmp.length-1] = '0'+tmp[tmp.length-1]
                        }
                        labels[index]=tmp.join(' ')
                    })
                    labels = labels.sort()
                    _.range(0,labels.length).forEach((current,index) => {
                        tmp = labels[index].split(' ')
                        if (tmp[tmp.length-1][0]==='0'){
                            tmp[tmp.length-1] = tmp[tmp.length-1][1]
                        }
                        labels[index]=tmp.join(' ')
                    })
                    if (statsArr.length === 0) {
                        skipLoop = true
                    }
                    let plot1data=[]
                    let matchs1=[]
                    let matchs2=[]
                    let plot2data=[]
                    let gameID=""
                    let idx = 0
                    _.range(0,labels.length).forEach((current,index) => {
                        gameID=labels[index]
                        if (labels1.includes(gameID)){
                            idx = labels1.indexOf(gameID)
                            plot1data.push(statsArr[idx])
                            matchs1.push(matchups[idx])
                        } else {
                            plot1data.push('0')
                            matchs1.push('NA')
                        }
                        if (labels2.includes(gameID)){
                            idx = labels2.indexOf(gameID)
                            plot2data.push(statsArr2[idx])
                            matchs2.push(matchups2[idx])
                        } else {
                            plot2data.push('0')
                            matchs2.push('NA')
                        }
                    })
                    let tmp1=0
                    let tmp2=0
                    _.range(0,plot1data.length).forEach((current,index) => {
                        if (plot1data[index]==='0' || plot1data[index]==='0.0' || plot1data[index]==='') {
                            tmp1 += 1
                        }
                    })
                    _.range(0,plot2data.length).forEach((current,index) => {
                        if (plot2data[index]==='0' || plot2data[index]==='0.0' || plot2data[index]==='') {
                            tmp2 += 1
                        }
                    })
                    if (tmp1 === plot1data.length && tmp2 === plot2data.length) {
                        skipLoop = true
                    }
                    // let playerStats=[passYdsArr,passTdsArr,passCompArr,passIntsArr,passQbrArr]
                    this.setState({isQB : true})
                    if (skipLoop === false){
                    const average = array => array.reduce((a, b) => a + b) / array.length
                    let avArrLastYear = []
                    let avArrLastYear2 = []
                    if (lasyYearArr.length > 0){
                        avArrLastYear = Array(lasyYearArr.length).fill(average(lasyYearArr.map(Number))).map(function(each_element){
                            return Number(each_element.toFixed(2));
                        })
                    }
                    if (lasyYearArr2.length > 0){
                        avArrLastYear2 = Array(lasyYearArr2.length).fill(average(lasyYearArr2.map(Number))).map(function(each_element){
                            return Number(each_element.toFixed(2));
                        })
                    }
                    if (index <= 11){
                        averages.push(avArrLastYear[0]/lastYearAverages[qbChartTitle[index]])
                        averages2.push(avArrLastYear2[0]/lastYearAverages[qbChartTitle[index]])
                        if (window.innerWidth <= 600) {
                            averagesLabels.push(mobileTitles[index])
                        } else {
                            averagesLabels.push(qbChartTitle[index])
                        }
                    }
                    let PointRadius = 0
                    if (statsArr.length===1){
                        PointRadius = 2
                    }
         
                    // _.range(1, 6).forEach((current,index)=>{
                        this.setState(state=>({data : [...state.data,{
                            matchup: matchs1,
                            labels: labels,
                            averages: averages,
                            averages2: averages2,
                            radarNames: [playerA.name,playerB.name],
                            averagesLabels2: matchs2,
                            averagesLabels: averagesLabels,
                            datasets: [
                                {
                                    title: qbChartTitle[index],
                                    backgroundColor: "rgb(30, 144, 255)",
                                    borderColor: "rgb(30, 144, 255)",
                                    data: plot1data,
                                    radius: PointRadius,
                                    hitRadius: 5,
                                    label: playerA.name
                                },
                                {
                                    // title: qbChartTitle[index],
                                    backgroundColor: "rgb(255, 0, 0)",
                                    borderColor: "rgb(255, 0, 0)",
                                    data: plot2data,
                                    radius: 0,
                                    hitRadius: 5,
                                    label: playerB.name
                                },
                            ],
                            options: {
                                plugins: {
                                    tooltip: {
                                        callbacks: {
                                            label: function(context) {
                                                
                                                let label = context.dataset.label || '';
                        
                                                if (label) {
                                                    label += ': ';
                                                }
                                                if (context.parsed.y !== null) {
                                                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                                                }
                                                return label;
                                            }
                                        }
                                    }
                                }
                            }
                        }]}))
                    // })
                // }
                    }
                }
            })
    // })
        } else {
            // this.props.history.push("/stats");
        }
    }

    // renderedData = () => {
    //     console.log(this.state.isQB)

    //     return (
    //         <div>
    //         {this.state.isQB && <Line data={this.state.data[0]} />}
            
    //     </div>
    //     )
    // }


           
    render() {
        let headerheight = 0
        if (document.getElementById('headerID')){
            headerheight = document.getElementById('headerID').clientHeight;
        }
        let chartType = ""
        let radarDataPoints = []
        let radarDataPoints2 = []
        let name1 = ''
        let name2 = ''
        let radarLabels = []
        let maxRadarPoint = 0
        if (this.state.data[0]) {
            radarDataPoints = this.state.data[0].averages
            radarDataPoints2 = this.state.data[0].averages2
            maxRadarPoint = Math.max(radarDataPoints)
            radarLabels = this.state.data[0].averagesLabels
            name1 = this.state.data[0].radarNames[0]
            name2 = this.state.data[0].radarNames[1]
        }
        let radarOptions = {
            scales: {
                r: {
                    angleLines: {
                        display: true
                    },
                    suggestedMin: 0,
                    suggestedMax: maxRadarPoint
                }
            },
            plugins: {
                title: {
                    display: true,
                    text: 'Average Performance Last Year',
                    font: {
                        size: 20
                    }
                },
                subtitle: {
                    display: true,
                    text: "Last year's league average performance equals 1. Values greater than 1 means the player performed better than average."
                },
                legend: {
                    display: true
                },
            }
        }
        let radarData = {
            labels: radarLabels,
            datasets: [
              {
                label: name1,
                data: radarDataPoints,
                backgroundColor: 'rgba(30, 144, 255,0.2)',
                borderColor:'rgba(30, 144, 255,0.2)',
                borderWidth: 1,
              },
              {
                label: name2,
                data: radarDataPoints2,
                backgroundColor: 'rgba(255, 0, 0,0.2)',
                borderColor:'rgba(255, 0, 0,0.2)',
                borderWidth: 1,
              },
            ],
          };
          let radarSize = "80%"
        if (window.innerWidth > 600) {
            radarSize = "60%"
        }
        let renderedData = this.state.data.map((chart)=> {
            let tmp = chart.datasets[0].title
            let renderHeader = false
            let headerText = undefined
            if (tmp.includes("Week")) {
                if (chartType !== 'byweek') {
                    chartType = 'byweek'
                    renderHeader = true
                    headerText = "Performance by Week"
                }
                chartType = 'byweek'
            } else if (tmp.includes("Against")) {
                if (chartType !== 'byopp') {
                    chartType = 'byopp'
                    renderHeader = true
                    headerText = "Performanc by Opponent"
                }
                chartType = 'byopp'
            } else if (tmp.includes("Home") || tmp.includes("Road")) {
                if (chartType !== 'byhoa') {
                    chartType = 'byhoa'
                    renderHeader = true
                    headerText = "Performance by Location"
                }
                chartType = 'byhoa'
            } else if (tmp.includes("doors")) {
                if (chartType !== 'byino') {
                    chartType = 'byino'
                    renderHeader = true
                    headerText = "Performance by Environment"
                }
                chartType = 'byino'
            } else {
                if (chartType !== 'base') {
                    chartType = 'base'
                    renderHeader = true
                    headerText = "Overall Performance Metrics"
                                    
                                
                }
                chartType = 'base'
            }
            // console.log(chart.charttype)
            let options = {maintainAspectRatio: false, 
                           plugins:{
                                zoom: {
                                    pan: {
                                    mode: "x",
                                    enabled: true
                                    },
                                    limits: {
                                    // axis limits
                                    },
                                    zoom: {
                                    mode: "x",
                                    wheel: {
                                        enabled: true,
                                    },
                                    pinch: {
                                        enabled: true
                                    },
                                    }
                                },
                                title: {
                                    display: true,
                                    text: chart.datasets[0].title
                                },
                                legend:{
                                    display:true
                                },
                                tooltip: {
                                    displayColors: false,
                                    position: "nearest",
                                    callbacks: {
                                        label: function (context, d) {
                                            if (context.datasetIndex === 0) {
                                            // function(context) {
                                                let label = context.dataset.label || '';
                                                if (context.parsed.y !== null) {
                                                    label += chart.matchup[context.dataIndex] + ": " + context.parsed.y;
                                                }
                                                return label;
                                            // }
                                            } else {
                                                let label = context.dataset.label || '';
                                                if (context.parsed.y !== null) {
                                                    label += chart.averagesLabels2[context.dataIndex] + ": " + context.parsed.y;
                                                }
                                                return label;
                                            }
                                        }
                                    }
                                }
                            }
                           }
            return (
                    <div key={chart.datasets[0].title} style={{height:"fit-content",display: "contents"}}>
                        {renderHeader && <div  className = "container" style={{width:'100%'}}>
                            <div  style={{width:'100%',height:"fit-content"}}>
                                <h3 className="col s12 center-align" style={{fontFamily: "Gruppo"}}>{headerText}</h3>
                                <div className="divider" style={{width:'100%'}}></div>
                            </div>
                        </div>}
                        <div  className="col s10 m6" >
                            {<Line data={chart} height={"300px"} options={options} />}    
                        </div>
                    </div>
                    )
        } )
        return(
            <div className="container" style={{width: "100%"}}>
                {/* <div className="row" > */}
                
                    <div className="container"  style={{width: "100%",fontFamily: "Gruppo",position: "sticky", top: `${headerheight}px`, margin: "0px",backgroundColor: "white"}}>
                        {/* <div className="row" style={{width:"100%"}}> */}
                            {/* <button onClick={()=>this.props.history.push("/stats")} className="col s2 left-align center-align blue white-text" style={{borderRadius : "20px", overflow:"hidden", padding: "5px 30px", border: "none", cursor:"pointer", fontFamily:"Gruppo"}}>Go Back</button> */}
                            {/* <div className="container"> */}
                            <div className="row" style={{width:"100%",margin: "0px",padding: "0px"}}>
                                <div className="col s4 m2">
                                    <Link 
                                        to='/stats' 
                                        style={{fontFamily: "Gruppo",fontSize: window.innerWidth > 800 ? "22px" : '16px'}}
                                        >
                                        Go Back
                                    </Link>
                                </div>
                                <div className="col s12 center-align">
                                    <h2  style={{display:"inline", fontFamily: "Gruppo",margin: "0px", width: "100%",fontSize: window.innerWidth > 800 ? '' : '18px'}}>{this.state.reportNameA}</h2><h4 style={{display:"inline",fontFamily: "Gruppo",fontSize: window.innerWidth > 800 ? '' : '14px'}}> vs </h4><h2  style={{display:"inline", fontFamily: "Gruppo",margin: "0px", width: "100%",fontSize: window.innerWidth > 800 ? '' : '18px'}}>{this.state.reportNameB}</h2>
                                </div>
                            </div>
                            {/* </div> */}
                            <div className="col s12 divider" style={{width:'100%'}}></div>
                        {/* </div> */}
                    </div>
                    <div  className="center-align" style={{width: radarSize, margin: "0 auto"}}>
                        <Radar data={radarData} options={radarOptions}/>
                    </div>
                    <div className="row" style={{height:"fit-content"}}>
                            {renderedData}
                    </div>
                {/* </div> */}
            </div>
        )  
    }
}

function mapStateToProps(state) {
    return {statsA: state.statsA,
            statsB: state.statsB,
            searchPlayerA: state.searchPlayerA, 
            searchPlayerB: state.searchPlayerB, 
            auth: state.auth}
}

export default withRouter(connect(mapStateToProps, {fetchPlayers, fetchName, setSearchPlayer})(Report))