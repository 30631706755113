const toolTipMap = {
    ARI:"ARI",
    ATL:"ATL",
    BAL:"BAL",
    BUF:"BUF",
    CAR:"CAR",
    CHI:"CHI",
    CIN:"CIN",
    CLE:"CLE",
    DAL:"DAL",
    DEN:"DEN",
    DET:"DET",
    GNB:"GB",
    GB: "GB",
    HOU:"HOU",
    IND:"IND",
    JAX:"JAX",
    KAN:"KC",
    KC: "KC",
    LVR:"LV",
    LV: "LV",
    LAR:"LAR",
    LAC:"LAC",
    MIA:"MIA",
    MIN:"MIN",
    NWE:"NE",
    NE: "NE",
    NOR:"NO",
    NO: "NO",
    NYG:"NYG",
    NYJ:"NYJ",
    PHI:"PHI",
    PIT:"PIT",
    SFO:"SF",
    SF: "SF",
    SEA:"SEA",
    TAM:"TB",
    TB: "TB",
    TEN:"TEN",
    WAS:"WAS",
    WSH:"WAS",
    SDG:"LAC",
    STL:"LAR",
    OAK:"LV"
    }

    export default toolTipMap