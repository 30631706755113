export const FETCH_USER = 'fetch_user';
export const FETCH_STATS = 'fetch_stats';
export const FETCH_STATSA = 'fetch_statsa';
export const FETCH_STATSB = 'fetch_statsb';
export const FETCH_PLAYERS = 'fetch_players';
export const AUTOCOMPLETE_CHANGED = "autocomplete_changed";
export const AUTOCOMPLETE_CHANGED_A = "autocomplete_changed_a";
export const AUTOCOMPLETE_CHANGED_B = "autocomplete_changed_b";
export const SEARCH_PLAYER = "search_player"
export const SEARCH_PLAYERA = "search_playerA"
export const SEARCH_PLAYERB = "search_playerB"
export const FETCH_PLAYERNAME = "fetch_playername"
export const REDUCE_CREDITS = "reduce_credits"
export const FETCH_REPORTS = "fetch_reports"
export const REDUCE_CREDITS_COMP = 'reduce_credits_comp'