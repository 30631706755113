import axios from 'axios';
import { FETCH_USER, FETCH_STATS, FETCH_STATSA, FETCH_STATSB, FETCH_PLAYERS, AUTOCOMPLETE_CHANGED,AUTOCOMPLETE_CHANGED_A,AUTOCOMPLETE_CHANGED_B, SEARCH_PLAYER,SEARCH_PLAYERA,SEARCH_PLAYERB, FETCH_PLAYERNAME, REDUCE_CREDITS, FETCH_REPORTS, REDUCE_CREDITS_COMP } from './types';

export const fetchUser = () => async dispatch => {
    const res = await axios.get('/api/current_user')
    dispatch({type: FETCH_USER, payload: res.data})
}

export const fetchReports = () => async dispatch => {
    const res = await axios.get('/api/my_reports')
    dispatch({type: FETCH_REPORTS, payload: res.data})
}

export const handleToken = (token) => async dispatch => {
    const res = await axios.post('/api/stripe', token);
    dispatch({type: FETCH_USER, payload: res.data})
}

export const fetchStats = (pid) => async dispatch => {
    if (pid) {
    const res = await axios.post('/api/stats', {playerID: pid});
    dispatch({type:FETCH_STATS, payload: res.data})
    } else {
        dispatch({type:FETCH_STATS, payload: []})
    }
}

export const fetchStatsA = (pid) => async dispatch => {
    if (pid) {
    const res = await axios.post('/api/stats', {playerID: pid});
    dispatch({type:FETCH_STATSA, payload: res.data})
    } else {
        dispatch({type:FETCH_STATSA, payload: []})
    }
}

export const fetchStatsB = (pid) => async dispatch => {
    if (pid) {
    const res = await axios.post('/api/stats', {playerID: pid});
    dispatch({type:FETCH_STATSB, payload: res.data})
    } else {
        dispatch({type:FETCH_STATSB, payload: []})
    }
}

export const fetchName = (pid) => async dispatch => {
    const res = await axios.post('/api/playername', {playerID: pid});
    dispatch({type:FETCH_PLAYERNAME, payload: res.data})
    return res.data;
}

export const fetchPlayers = () => async dispatch => {
    const res = await axios.get('/api/players');
    dispatch({type:FETCH_PLAYERS, payload: res.data})
}

export const autoCompleteChanged = autoCompleteValue => ({
  type: AUTOCOMPLETE_CHANGED,
  autoCompleteValue
});

export const autoCompleteChangedA = autoCompleteValueA => ({
    type: AUTOCOMPLETE_CHANGED_A,
    autoCompleteValueA
  });

  export const autoCompleteChangedB = autoCompleteValueB => ({
    type: AUTOCOMPLETE_CHANGED_B,
    autoCompleteValueB
  });

export const setSearchPlayer = (player) => dispatch => {
    if (player){
        dispatch({type: SEARCH_PLAYER, payload : player})
    } else {
        dispatch({type: SEARCH_PLAYER, payload : []})
    }
} 

export const setSearchPlayerA = (player) => dispatch => {
    if (player){
        dispatch({type: SEARCH_PLAYERA, payload : player})
    } else {
        dispatch({type: SEARCH_PLAYERA, payload : []})
    }
} 

export const setSearchPlayerB = (player) => dispatch => {
    if (player){
        dispatch({type: SEARCH_PLAYERB, payload : player})
    } else {
        dispatch({type: SEARCH_PLAYERB, payload : []})
    }
} 

export const reduceCredits = (values, currentWeek) => async dispatch => {
    const res = await axios.post('/api/reduce_credits',{pid: values,currentWeek:currentWeek})
    dispatch({type: REDUCE_CREDITS, payload : res.data})
} 

export const reduceCreditsComp = (pidA, pidB, currentWeek) => async dispatch => {
    const res = await axios.post('/api/reduce_credits_comp',{pidA:pidA, pidB:pidB, currentWeek:currentWeek})
    dispatch({type: REDUCE_CREDITS_COMP, payload : res.data})
} 