import "./component.css"

function Table({data, config, keyFn}) {

    const renderedRows = data.map((rowData) => {

        const renderedCells = config?.map((column)=>{
            return <td key={column.label} className="p-3" style={{lineHeight: "5px"}}>{column.render(rowData)}</td>
        })

        return(
            <tr className="border-b" key={keyFn(rowData)}>
                {renderedCells}
            </tr>
        )
    })

    const renderedHeaders = config?.map((column) => {
        return (
            <th key={column.label}>{column.label}</th>
        )
    })

    return  <div className="tableContainer">
                <table className="striped centered">
                    <thead>
                        <tr className="border-b-2">
                            {renderedHeaders}
                        </tr>
                    </thead>
                    <tbody>
                        {renderedRows}
                    </tbody>
                </table>
            </div>
}

export default Table;